import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const Hero = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const handleContactClick = () => {
		navigate("/contact-us");
	};

	return (
		<section
			className="relative h-screen bg-cover bg-center flex items-center"
			style={{
				backgroundImage:
					"url(https://res.cloudinary.com/dmwvkjqsn/image/upload/v1718632746/AccountingBusinessCDN/c9euehjdrnbtrhyayppu.webp)",
				maxHeight: "70vh",
			}}
		>
			<div className="absolute inset-0 bg-black opacity-50"></div>
			<div className="container relative z-10 text-center">
				<div className="grid grid-cols-1 gap-5 place-items-center">
					<div className="order-1 sm:order-2 xl:pr-40">
						<h1 className="text-4xl sm:text-5xl font-semibold text-white">
							{t("heroTitle")}
						</h1>
						<p className="text-white mt-4">
							{t("heroDescription")}
						</p>

						{/* Added landline phone number */}
						<p className="text-white mt-4">
							{t("landlinePhone")}: 22410 43755
						</p>

						{/* Mobile phone number */}
						<p className="text-white mt-2">
							{t("mobilePhone")}: +30 695 129 0680
						</p>
						<p className="text-white mt-2">
							{t("email")}: premtisk@gmail.com
						</p>
						<p className="text-white mt-2">
							{t("adress")}: {t("street")} 49, {t("city")}, 85132
						</p>

						{/* Email */}
						<button
							onClick={handleContactClick}
							className="primary-btn mt-6"
						>
							{t("heroButton")}
						</button>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Hero;
