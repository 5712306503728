import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "../pages/Homepage";
import Navbar from "../components/Navbar/Navbar";
import ContactUs from "../pages/ContactUs";
import AboutUs from "../pages/AboutUs";
import { Helmet } from "react-helmet";

const RouterManagement = () => {
	return (
		<BrowserRouter>
			<Helmet>
				<script
					async
					src="https://www.googletagmanager.com/gtag/js?id=AW-16795611402"
				></script>
				<script>
					{`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'AW-16795611402');
          `}
				</script>
			</Helmet>
			<div className="overflow-x-hidden">
				<Navbar />
				<Routes>
					<Route path="/" element={<HomePage />} />
					<Route path="/contact-us" element={<ContactUs />} />
					<Route path="/about-us" element={<AboutUs />} />
				</Routes>
			</div>
		</BrowserRouter>
	);
};

export default RouterManagement;
